<template>
    <div v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="settledInfo-container">
        <div class="help-title">
            <span>入驻信息管理</span>
            <span></span>
        </div>
        <div class="help-screen"> 
            <span style="margin-right: 16px">内容类型:</span>
            <common-date @commonGetDate="commonGetDate" :radio="commonRadio" :startDate="commonStartDate"  :endDate="commonEndDate" ref="commonReset"></common-date>
        </div>
        <div class="help-main">
            <el-table class="common-table" border style="margin: 0" height="100%" :data="tableData">
                <el-table-column label="团队名称">
                    <template slot-scope="scope">
                        <el-button type="text" @click="() => { detailShow(scope.row.id) }">{{scope.row.teamName}}</el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="account" label="姓名" :formatter="tableColumn" ></el-table-column>
                <el-table-column prop="phone" label="联系方式" :formatter="tableColumn" ></el-table-column>
                <el-table-column prop="contractType" label="签约类型" :formatter="tableColumn" ></el-table-column>
                <el-table-column prop="teamIntroduce" label="团队介绍" :formatter="tableColumn" ></el-table-column>
                <el-table-column prop="wxId" label="QQ/微信" :formatter="tableColumn" ></el-table-column>
                <el-table-column prop="address" label="所在城市" :formatter="tableColumn" ></el-table-column>
                <el-table-column prop="createTime" label="申请时间" :formatter="tableColumn" ></el-table-column>
                <el-table-column label="跟进状态">
                    <template slot-scope="scope">
                        <span v-if="scope.row.contactState == '未联系'" class="table-label-red">未联系</span>
                        <span v-if="scope.row.contactState == '签约中' || scope.row.contactState == '联系中'" class="table-label-green">签约中</span>
                        <span v-if="scope.row.contactState == '已签约'" class="table-label-blue">已签约</span>
                        <span v-if="scope.row.contactState == '流失' || scope.row.contactState == '废弃'" class="table-label-grey">流失</span>
                    </template>
                </el-table-column>
                <el-table-column prop="updateBy" label="跟进人" :formatter="tableColumn" ></el-table-column>
            </el-table>
        </div>
        <div class="page-container">
            <el-pagination
                @size-change="(e) => pageFun(e, 'pageSize')"
                @current-change="(e) => pageFun(e)"
                style="text-align: center"
                :page-sizes="pagination.pageSizes"
                :page-size="params.pageSize"
                :current-page="params.page"
                layout="total, prev, pager, next,sizes, jumper"
                :total="pagination.total"
                >
            </el-pagination>
        </div>
        <settled-detail ref="settledDetail" @settledList="settledList"></settled-detail>
    </div>
</template>
<script>
import { tableColumn, DateTransform } from "../../utils/index.js"
import { settledList } from "../../service/manage.js"
import CommonDate from "../../components/common/commonDate.vue"
import SettledDetail from "./children/settledDetail.vue"
export default {
    components: { CommonDate, SettledDetail },
    data() {
        return {
            tableColumn,
            loading: false,
            tableData: [{}], // 表格数据
            params: {
                page: 1,
                pageSize: 10,
                startTime:  DateTransform(new Date),
                endTime: DateTransform(new Date)
            },
            pagination: {
                total: 0,
                pageSizes: [5, 10, 20, 50, 100]
            },
            commonRadio: 1, // 时间筛选默认选中
            commonStartDate: DateTransform(new Date), // 时间筛选默认选中
            commonEndDate: DateTransform(new Date), // 时间筛选默认选中
        }
    },
    mounted () {
        this.settledList()
    },
    methods: {
        detailShow(id) { // 显示详情
            this.$refs['settledDetail'].getDetailData(id)
        },
        resetBtn() { // 重置
            this.$refs.commonReset.resetFun(this.commonRadio, this.commonStartDate, this.commonEndDate)
            this.params = {
                page: 1,
                pageSize: 10,
                startTime: DateTransform(new Date),
                endTime: DateTransform(new Date)
            };
            this.settledList()
        },
        commonGetDate (startTime, endTime) { // 时间筛选
            this.params.startTime = startTime
            this.params.endTime = endTime
            this.settledList()
        },
        async settledList() { // 入驻信息列表
            let params = { ...this.params }
            this.loading = true
            let { data, code } = await settledList(params)
            this.loading = false
            if (code == 200) {
                this.tableData = data.list
                this.pagination.total = data.total
            }
        },
        commonFun () { // 筛选调用
            this.params.page = 1
            this.settledList()
        },
        pageFun(e, type) { // 分页
            if (type == "pageSize") {
                this.params.pageSize = e;
            } else {
                this.params.page = e;
            }
            this.settledList();
        },
    }
}
</script>
<style lang="less" scoped>
.settledInfo-container {
    text-align: left;
    height: 100%;
    display: flex;
    flex-direction: column;
    .table-label-red {
        display: inline-block;
        font-size: 12px;
        line-height: 24px;
        height: 24px;
        padding: 0 12px;
        color: #FE4C4E;
        border: 1px solid #FE4C4E;
        background: #F852C419;
        border-radius: 2px;
    }
    .table-label-green {
        display: inline-block;
        font-size: 12px;
        line-height: 24px;
        height: 24px;
        padding: 0 12px;
        color: #52C419;
        border: 1px solid #52C419;
        background: rgba(82, 196, 25, 0.1);
        border-radius: 2px;
    }
    .table-label-blue {
        display: inline-block;
        font-size: 12px;
        line-height: 24px;
        height: 24px;
        padding: 0 12px;
        color: #1890FF;
        border: 1px solid #1890FF;
        background: rgba(24, 144, 255, 0.1);
        border-radius: 2px;
    }
    .table-label-grey {
        display: inline-block;
        font-size: 12px;
        line-height: 24px;
        height: 24px;
        padding: 0 12px;
        color: #aaa;
        border: 1px solid #aaa;
        background: rgba(24, 144, 255, 0.1);
        border-radius: 2px;
    }
    .page-container {
        padding-bottom: 32px;
    }
    /deep/.el-table th {
        background: #f7f7f7;
    }
    .help-main {
        flex: 1;
        overflow: auto;
        padding: 32px;
        box-sizing: border-box;
    }
    .help-title {
        display: flex;
        height: 72px;
        box-sizing: border-box;
        justify-content: space-between;
        padding: 0 32px;
        align-items: center;
        font-size: 24px;
        border-bottom: 1px solid #E6E6E6;
        span {
            font-size: 24px;
        }
    }
    .help-screen {
        height: 96px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding: 0 32px;
        border-bottom: 16px solid #eee;
    }
}
</style>